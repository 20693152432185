import React from 'react'

import { HeaderProps } from 'components/Header'
import StaticLayoutNavBar from './StaticLayoutNavBar'
import StaticLayoutFooter from './StaticLayoutFooter'

export interface AppLayoutProps {
  headerProps?: HeaderProps
}

const WebsiteLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <div className="w-full min-h-screen relative">
      <StaticLayoutNavBar />
      <div className="w-full min-h-screen relative">
        <div className="mx-auto max-w-7xl">{children}</div>
      </div>
      <StaticLayoutFooter />
    </div>
  )
}

export default WebsiteLayout
