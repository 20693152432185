/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link'
import React from 'react'
import {
  FaYoutubeSquare,
  FaDiscord,
  FaGithub,
  FaTwitterSquare,
} from 'react-icons/fa'

const StaticLayoutFooter = () => {
  return (
    <>
      {/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
// ...
require('@tailwindcss/forms'),
    ]
  }
  ```
*/}
      <footer
        className="border-t bg-flowStep05 border-flowStep20"
        aria-labelledby="footerHeading"
      >
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="px-4 py-12 mx-auto max-w-7xl lg:py-16">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="grid grid-cols-2 gap-8 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold tracking-wider uppercase text-flowStep60">
                    About FlowStep
                  </h3>
                  <ul className="pl-0 mt-4 space-y-4">
                    <li>
                      <Link href="/about-flowstep/core-functionality">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          Functionality
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/about-flowstep/flowstep-features">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          Features
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/about-flowstep/digital-business-transformation">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          Digital Business
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/about-flowstep/ui-backend-development">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          Software Development
                        </a>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold tracking-wider uppercase text-flowStep60">
                    Support
                  </h3>
                  <ul className="pl-0 mt-4 space-y-4">
                    <li>
                      <Link href="/pricing">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          Pricing
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/larger-plans">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          Larger Plans
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/frequently-asked-questions">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          FAQs
                        </a>
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://discord.gg/YZnZ5Krgty"
                        className="text-sm text-flowStep60 hover:text-flowStep80"
                        title="Visit our Discord Community (external link)"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Discord Community{' '}
                        <svg
                          className="inline-block w-5 h-5 -mt-1"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.reddit.com/r/flowstep/"
                        className="text-sm text-flowStep60 hover:text-flowStep80"
                        title="Visit the FlowStep Subreddit  (external link)"
                        target="_blank"
                        rel="noreferrer"
                      >
                        FlowStep Subreddit{' '}
                        <svg
                          className="inline-block w-5 h-5 -mt-1"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    {/*<li>
                      <a
                        href="/"
                        className="text-base text-flowStep40 hover:text-flowStep60"
                      >
                        Documentation
                      </a>
                    </li>*/}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold tracking-wider uppercase text-flowStep60">
                    Company
                  </h3>
                  <ul className="pl-0 mt-4 space-y-4">
                    <li>
                      <Link href="/about-us/">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          The Company
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/about-us/leadership-advisors">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          Leadership and Advisors
                        </a>
                      </Link>
                    </li>
                    {/* <li>
                      <a
                        href="/"
                        className="text-base text-flowStep40 hover:text-flowStep60"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-base text-flowStep40 hover:text-flowStep60"
                      >
                        Jobs
                      </a>
                    </li>*/}
                    <li>
                      <Link href="/contact-us">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          Contact Us
                        </a>
                      </Link>
                    </li>
                    {/*
                    <li>
                      <Link href="/investors">
                        <a className="text-sm text-flowStep60 hover:text-flowStep80">
                          Invest in FlowStep
                        </a>
                      </Link>
                    </li>*/}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold tracking-wider uppercase text-flowStep60">
                    Legal
                  </h3>
                  <ul className="pl-0 mt-4 space-y-4">
                    <li>
                      <a
                        href="/privacy"
                        className="text-sm text-flowStep60 hover:text-flowStep80"
                      >
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a
                        href="/terms"
                        className="text-sm text-flowStep60 hover:text-flowStep80"
                      >
                        Terms
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-8 xl:mt-0">
              <h3 className="text-sm font-semibold tracking-wider uppercase text-flowStep80">
                Subscribe to our newsletter
              </h3>
              <p className="mt-4 mb-0 text-sm text-flowStep60">
                The latest news, articles, and resources, sent to your inbox on
                a sporadic frequency (we're pretty busy).
              </p>
              <p className="mt-2 text-sm text-flowStep60">
                You <strong>will</strong> need to verify your email address.
              </p>
              <form className="mt-4 sm:flex sm:max-w-md">
                <label htmlFor="emailAddress" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="emailAddress"
                  id="emailAddress"
                  autoComplete="email"
                  required
                  className="w-full min-w-0 px-4 py-2 text-base bg-white border border-transparent rounded-md appearance-none text-flowStep80 placeholder-flowStep050 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-flowStep80 focus:ring-white focus:border-white focus:placeholder-flowStep40"
                  placeholder="Enter your email"
                />
                <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                  <button
                    type="submit"
                    className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-flowStep60 hover:bg-flowStep80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-flowStep80"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="pt-8 mt-8 border-t border-flowStep20 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              <a
                href="https://www.youtube.com/useflowStep"
                className="text-2xl text-flowStep60 hover:text-flowStep80"
                title="youtube.com/useFlowStep"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">YouTube</span>
                <FaYoutubeSquare />
              </a>
              <a
                href="https://twitter.com/useflowStep"
                className="text-2xl text-flowStep60 hover:text-flowStep80"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">Twitter</span>
                <FaTwitterSquare title="twitter.com/useFlowStep" />
              </a>
              <a
                href="#"
                className="text-2xl text-flowStep60 hover:text-flowStep80"
              >
                <span className="sr-only">GitHub</span>
                <FaGithub title="github.com/useflowstep" />
              </a>
              <a
                href="https://discord.gg/YZnZ5Krgty"
                className="text-2xl text-flowStep60 hover:text-flowStep80"
                title="Discord"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">Discord</span>
                <FaDiscord title="Discord" />
              </a>
            </div>
            <p className="mt-8 mb-0 text-sm text-flowStep60 md:mt-0 md:order-1">
              &copy; 2021 FlowStep Tech Pty Ltd. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default StaticLayoutFooter
