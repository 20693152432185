import Button from 'components/Button'
import StaticNavLink from 'layouts/StaticLayout/StaticNavLink'
import StaticNavLinkMobile from 'layouts/StaticLayout/StaticNavLinkMobile'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

const StaticLayoutNavBar = () => {
  const [showMobileNav, setShowMobileNav] = React.useState(false)

  const router = useRouter()

  const isAboutFlowStep = router?.pathname === '/about-flowstep'
  const isFeatures = router?.pathname === '/about-flowstep/features'
  const isAboutUs = router?.pathname === '/about-us'
  const isPricing = router?.pathname === '/pricing'
  const isLargerPlans = router?.pathname === '/larger-plans'
  const isInvestors = router?.pathname === '/investors'

  return (
    <nav className="bg-flowStep">
      <div className="px-4 mx-auto max-w-7xl">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link href="/">
                <a className="flex items-center flex-shrink-0">
                  <img
                    className="block w-auto h-8"
                    src="/img/logo/white.svg"
                    alt="Flowstep"
                  />
                </a>
              </Link>
            </div>
            <div className="hidden md:block">
              <div className="flex items-baseline ml-10 space-x-4">
                <StaticNavLink
                  label="About FlowStep"
                  pathname="/about-flowstep"
                  shouldShadow={isAboutFlowStep}
                />
                <StaticNavLink
                  label="Pricing"
                  pathname="/pricing"
                  shouldShadow={isPricing}
                />
                <StaticNavLink
                  label="Larger Plans"
                  pathname="/larger-plans"
                  shouldShadow={isLargerPlans}
                />
                {/* <StaticNavLink
                  label="Partner Program"
                  pathname="/partner-program"
                  shouldShadow={isPartnerProgram}
                /> */}
                <StaticNavLink
                  label="The Company"
                  pathname="/about-us"
                  shouldShadow={isAboutUs}
                />
                {/*
                <StaticNavLink
                  label="Investors"
                  pathname="/investors"
                  shouldShadow={isInvestors}
                /> */}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="flex items-center ml-4 md:ml-6">
              <Link href="/login">
                <a>
                  <Button color="primary">Login</Button>
                </a>
              </Link>
              <Link href="/pricing">
                <a>
                  <Button color="error" className="ml-3">
                    Register
                  </Button>
                </a>
              </Link>
            </div>
          </div>
          <div className="flex -mr-2 md:hidden">
            {/* Mobile menu button */}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-flowStep40 hover:text-white hover:bg-flowStep60 focus:outline-none focus:bg-flowStep60 focus:text-white"
              onClick={() => setShowMobileNav(!showMobileNav)}
            >
              {/* Menu open: "hidden", Menu closed: "block" */}
              <svg
                className="block w-6 h-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Menu open: "block", Menu closed: "hidden" */}
              <svg
                className="hidden w-6 h-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/*
  Mobile menu, toggle classes based on menu state.

  Open: "block", closed: "hidden"
      */}
      {showMobileNav && (
        <div className={`${showMobileNav ? '' : 'hidden'}md:hidden`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <StaticNavLinkMobile
              label="Features"
              pathname="/features"
              shouldShadow={isFeatures}
            />
            <StaticNavLinkMobile
              label="Pricing"
              pathname="/pricing"
              shouldShadow={isPricing}
            />
            <StaticNavLinkMobile
              label="Larger Plans"
              pathname="/larger-plans"
              shouldShadow={isLargerPlans}
            />
            {/* <StaticNavLinkMobile
              label="Partner Program"
              pathname="/partner-program"
              shouldShadow={isPartnerProgram}
            /> */}
            <StaticNavLinkMobile
              label="The Company"
              pathname="/about-us"
              shouldShadow={isAboutUs}
            />
            {/*
            <StaticNavLinkMobile
              label="Investors"
              pathname="/investors"
              shouldShadow={isInvestors}
            /> */}
          </div>
          <div className="pt-4 pb-3 border-t border-flowStep20">
            <div className="flex items-center px-5 space-x-3">
              <Link href="/login">
                <a>
                  <Button color="primary">Login</Button>
                </a>
              </Link>
              <Link href="/pricing">
                <a>
                  <Button color="error">Register</Button>
                </a>
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  )
}

export default StaticLayoutNavBar
