import cx from 'classnames'

const Spinner: React.FC<{
  size?: number
  color?: string
  visible?: boolean
  className?: string
}> = ({ color, size, visible, className }) => {
  return (
    <div
      style={{
        border: '2px solid #f3f3f3',
        borderLeft: `2px solid ${color || '#3498db'}`,
        width: `${size || 24}px`,
        height: `${size || 24}px`,
      }}
      className={cx(
        'animate-spin bg-transparent rounded-full ',
        {
          hidden: !visible,
        },
        className,
      )}
    ></div>
  )
}

export default Spinner
