import classnames from 'classnames'
import Spinner from 'components/Commons/Loading/Spinner'
import React from 'react'

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type Color =
  | 'add'
  | 'add_outlined'
  | 'save'
  | 'save_outlined'
  | 'cancel'
  | 'edit'
  | 'search'
  | 'modal'
  | 'promote'
  | 'primary'
  | 'secondary'
  | 'default'
  | 'error'
  | 'danger'
  | 'primary_outlined'
  | 'danger_outlined'
  | 'warning'

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: Size
  color?: Color
  block?: boolean
  loading?: boolean
  onClick?: (args?: any) => void
}

const Button: React.FC<ButtonProps> = React.forwardRef(
  (
    {
      children,
      size = 'md',
      color = 'default',
      loading,
      type = 'button',
      block,
      className,
      disabled,
      ...btnProps
    },
    ref,
  ) => {
    const classes = classnames(
      'inline-flex items-center  font-medium text-white focus:outline-none transition ease-in-out duration-150',
      {
        rounded: size === 'xs',
        'rounded-md': size !== 'xs',
        'leading-4': size === 'xs' || size === 'sm',
        'leading-5': size === 'md',
        'leading-6': size === 'lg' || size === 'xl',
        'text-base': size === 'lg' || size === 'xl',
        'text-sm': size === 'sm' || size === 'md',
        'text-xs': size === 'xs',
        'px-2.5 py-1.5': size === 'xs',
        'px-3 py-2': size === 'sm',
        'px-4 py-2': size === 'md' || size === 'lg',
        'px-6 py-3': size === 'xl',
        'pointer-events-none opacity-50': disabled,
        'w-full': block,

        // Default
        'border border-transparent !text-flowStep60 bg-none border-flowStep20 hover:border-flowStep20 focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'default',

        // Add
        'border bg-flowStep hover:bg-flowStep150 border-flowStep hover:border-flowStep150 !text-white hover:!text-white focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'add',

        // Add Secondary
        'border-2 bg-white hover:bg-flowStep150 border-flowStep1 hover:border-flowStep150 !text-flowStep1 hover:!text-white focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'add_outlined',

        // Save
        'border bg-flowOutcome hover:bg-flowOutcome60 border-flowOutcome hover:border-flowOutcome60 !text-white hover:!text-white focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'save',

        // Save Secondary
        'border-2 bg-white hover:bg-flowOutcome border-flowOutcome hover:border-flowOutcome !text-flowOutcome hover:!text-white focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'save_outlined',

        // Cancel
        'border-2 bg-white hover:bg-flowAction border-flowAction60 hover:border-flowAction !text-flowAction60 hover:!text-white focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'cancel',

        // Edit
        'border bg-flowStep60 hover:bg-flowStep150 border-flowStep60 hover:border-flowStep150 !text-white hover:!text-white focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'edit',

        // Search
        'border bg-flowStep150 hover:bg-flowStep border-flowStep150 hover:border-flowStep !text-white hover:!text-white focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'search',

        // Open Modal
        'border bg-flowStep60 hover:bg-flowStep40 border-flowStep60 hover:border-flowStep40 !text-white hover:!text-white focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'modal',

        // Promote
        'border bg-flowAPI hover:bg-flowAPI60 border-flowAPI hover:border-flowAPI60 !text-white hover:!text-white focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'promote',

        'bg-flowStep150 hover:bg-flowStep60 focus:border-flowStep60 focus:ring-flowStep60 active:bg-flowStep80':
          color === 'primary',
        'border border-transparent !text-flowStep150 hover:!text-white border-flowStep150 focus:border-flowStep60 focus:ring-flowStep60 active:border-flowStep80 bg-white  hover:bg-flowStep150':
          color === 'primary_outlined',
        'bg-flowStep40 hover:bg-flowStep60 focus:border-flowStep60 focus:ring-flowStep60 active:bg-flowStep60':
          color === 'secondary',
        'border border-transparent bg-flowStep05 !text-flowAction hover:!text-white border-flowStep20 hover:border-flowAction hover:bg-flowAction focus:border-flowAction focus:ring-red active:bg-flowAction':
          color === 'danger',
        'border border-transparent !text-flowAction hover:!text-white border-flowAction hover:bg-flowAction focus:border-flowAction focus:ring-red':
          color === 'danger_outlined',
        'border bg-flowAction hover:bg-flowAction border-flowAction hover:border-flowAction !text-white hover:!text-flowStep focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'error',
        'border bg-flowAction hover:bg-flowAction60 border-flowAction hover:border-flowAction60 !text-white hover:!text-flowStep focus:border-flowStep40 focus:ring-flowStep60 active:border-flowStep40':
          color === 'warning',
      },
      className,
    )

    return (
      <button
        {...btnProps}
        type={type}
        className={classes}
        ref={ref}
        disabled={disabled || loading}
      >
        {loading ? <Spinner visible size={20} /> : children}
      </button>
    )
  },
)

Button.displayName = 'Button'

export default Button
