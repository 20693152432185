/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link'
import React from 'react'

const StaticNavLink = ({
  pathname,
  label,
  shouldShadow,
}: {
  pathname: string
  label: string
  shouldShadow: boolean
}) => {
  return (
    <>
      <Link href={pathname}>
        <a
          className={`px-3 py-2 rounded-md text-sm font-medium text-white hover:text-white hover:bg-flowStep150 focus:outline-none focus:text-white focus:bg-flowStep60 ${
            shouldShadow ? 'bg-flowStep150' : ''
          }`}
        >
          {label}
        </a>
      </Link>
      {/* <svg
        className="w-5 h-5 ml-2 text-flowStep40 group-hover:text-flowStep60"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg> */}
    </>
  )
}

export default StaticNavLink
