/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link'
import React from 'react'

const StaticNavLink = ({
  pathname,
  label,
  shouldShadow,
}: {
  pathname: string
  label: string
  shouldShadow: boolean
}) => {
  return (
    <Link href={pathname}>
      <a
        className={`block px-3 py-2 rounded-md text-base font-medium text-white focus:outline-none focus:text-white focus:bg-flowStep60 ${
          shouldShadow ? 'bg-flowStep80' : ''
        }`}
      >
        {label}
      </a>
    </Link>
  )
}

export default StaticNavLink
